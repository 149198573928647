<template>
  <div class="WxLogin">
    
  </div>
</template>
<script>
import cookieUtil from '../utils/CookieUtil'
export default {
  name: 'WxLogin',
  data(){
    return {
      token:"",
      expire:""
    }
  },
  created(){
    this.token = this.$route.query.token
    this.expire = this.$route.query.expire
    if(this.token && this.expire){
      cookieUtil.setCookie(cookieUtil.token,this.token,this.expire);
    }
    this.$router.push("/");
  }
}
</script>
